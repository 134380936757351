import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GlobalLoader } from "../../../components/GlobalLoader/GlobalLoader";
import { useCrefisaWhiteLabelData } from "../../../hooks/useCrefisaWhiteLabelData";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import { MailingResponseData } from "../../../shared/models/backend/mailing/mailing";
import { FileRequestDocumentEnum } from "../../../shared/models/enums/files.enum";
import useMailingOnBoardingService from "../../../shared/services/useMailingOnBoardingService";
import { EMailingStepsEnum, MailingWithDocVariantProps } from "../index.types";
import { InstructionStepVariantsEnum } from "../InstructionsStep";
import TakePhotoMultiFile from "./MultiFile";
import TakePhotoSingleFile from "./SingleFile";

const TakePhotoStep = ({
  variant,
  setActiveStep,
  photoToEdit,
}: { photoToEdit?: "front" | "back" } & MailingWithDocVariantProps) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [docVariant, setDocVariant] =
    useState<InstructionStepVariantsEnum | null>(null);
  const [mailingData, setMailingData] = useState<MailingResponseData>(null);
  const { getMailing } = useMailingOnBoardingService();
  // const { whitelabelLogo, whitelabelName } = useWhitelabel();
  const { logo, name } = useCrefisaWhiteLabelData();
  const theme = useThemeWhiteLabel();

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    getMailing(id).then((response) => {
      setMailingData(response);
    });
    // .finally(() => {
    //   setTimeout(() => {
    //     setIsLoading(false);
    //   }, 300);
    // });
  }, [id]);

  const getDocVariant = (
    fileType?: FileRequestDocumentEnum
  ): InstructionStepVariantsEnum => {
    if (fileType === FileRequestDocumentEnum.RG)
      return InstructionStepVariantsEnum.rg;
    if (fileType === FileRequestDocumentEnum.CNH)
      return InstructionStepVariantsEnum.cnh;
    return InstructionStepVariantsEnum.outro;
  };

  useEffect(() => {
    setDocVariant(
      mailingData?.additionalInfo?.documentType ? getDocVariant() : variant
    );

    if (docVariant) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
      return;
    }

    if (!!mailingData && !docVariant && !isLoading) {
      setActiveStep(EMailingStepsEnum.DocumentChooseType);
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [mailingData, isLoading,variant]);

  if (isLoading || !docVariant)
    return (
      <GlobalLoader
        logo={logo}
        alt={`${name}-logo`}
        color={theme.primaryColor}
        isFixed
      />
    );

  if (docVariant === InstructionStepVariantsEnum.rg)
    return (
      <TakePhotoMultiFile
        setActiveStep={setActiveStep}
        additionalInfo={mailingData?.additionalInfo}
        photoToEdit={photoToEdit}
      />
    );

  return (
      <TakePhotoSingleFile setActiveStep={setActiveStep} variant={docVariant} additionalInfo={mailingData?.additionalInfo} />
  );
};

export default TakePhotoStep;
