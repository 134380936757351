import { MenuOption } from "../components/Section/menuOption";
import Routes from "../../../constants/Routes";
import MenuGroup from "../components/Item/menuGroup";
import { UserProfileType } from "../../../shared/models/user-profile-type";
const AnyRole: UserProfileType[] = ['ADMCONTA', 'Financial', 'GeneralInquirer', 'Inquirer'];
export const inquiryMenuOptions: MenuOption[] = [
    {
        iconName: 'document', text: "Documento",
        multiOption: 
            [
                { iconName: "video-outline", text: "Análise manual", route: Routes.Inquiries.Document },
                { iconName: "video-outline", text: "Digital híbrida", route: Routes.Inquiries.DocumentAutomatic},
            ],
        roles: AnyRole
    },
    { iconName: 'id-card', text: "Documento + Selfie", route: Routes.Inquiries.DocumentWithSelfie,      multiOption: 
        [
            { iconName: "video-outline", text: "Análise manual", route: Routes.Inquiries.DocumentWithSelfie },
            { iconName: "video-outline", text: "Digital híbrida", route: Routes.Inquiries.DocumentWithSelfieAutomatic },
        ],
        roles: AnyRole },
    { iconName: "phone", text: "Consulta por Telefone", route: Routes.Inquiries.Phone, roles: AnyRole },
    { iconName: "envelope", text: "Consulta por E-mail", route: Routes.Inquiries.Email, roles: AnyRole },
    {
        iconName: "video-outline",
        text: "Onboarding por Vídeo",
        multiOption: process.env.REACT_APP_ENV != 'PROD' ?
            [
                { iconName: "video-outline", text: "Geral (Vídeo + Doc)", route: Routes.Inquiries.VideoOnboardingDoc },
                { iconName: "video-outline", text: "Geral (Vídeo)", route: Routes.Inquiries.VideoOnboardingNoDoc },
                { iconName: "video-outline", text: "Consignado (Vídeo + Doc)", route: Routes.Inquiries.PayrollLoanVideoOnboarding },
            ]
            : [{ iconName: "video-outline", text: "Geral", route: Routes.Inquiries.VideoOnboardingNoDoc },],
        roles: AnyRole
    },
    { iconName: "calendar", text: "Histórico de consultas", route: Routes.Inquiries.List, roles: AnyRole },
];

export const reportsMenuOptions: MenuOption[] = [
    { iconName: 'chart', text: "Relatório de performance", route: Routes.Reports.Performance, roles: AnyRole },
    { iconName: 'piggybank', text: "Extrato financeiro", route: Routes.Reports.Financial, roles: ['ADMCONTA', 'Financial'] },
    { iconName: 'download', text: "Baixar resultados de consultas", route: Routes.Reports.DownloadInquiriesResults, roles: AnyRole },
];

export const configurationsMenuOptions: MenuOption[] = [
    { iconName: 'manage-unities', text: "Gerenciar Unidades", route: Routes.Unities.List, roles: ['ADMCONTA'] },
    { iconName: 'user-cog', text: "Gerenciar Usuários", route: Routes.User.List, roles: ['ADMCONTA'] },
    { iconName: 'credit-card', text: "Planos / Pagamento", route: Routes.SettingsPlans, roles: ['ADMCONTA', 'Financial'] }
];

export const accountMenuOptions: MenuOption[] = [
    { iconName: 'address-card', text: "Dados da conta", route: Routes.Account.Profile, roles: AnyRole },
    { iconName: 'key', text: "Alterar senha", route: Routes.Account.ChangePassword, roles: AnyRole }
];

export const logoutMenuOption: MenuGroup = {
    iconName: "logout",
    options: [],
    label: 'Sair do app',
};