import React, {useState} from "react";
import {InnerContainer, OptionListContainer, StyledDiv} from "./styles";
import MenuGroup, {MATERIAL_ICONS} from "./menuGroup";
import OptionList from "../Section/optionList";
import Typography from "../../../Typography";
import {useItem} from "./useItem";
import {Palette} from "../../../../shared/theme/palette";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

type ItemProps = MenuGroup & {
    activeItem: string | null;
    setActiveItem: (
        item: ((prevState: string | null) => string | null) | string | null
    ) => void;
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    onClick?: () => void;
};

const Item: React.FC<ItemProps> = ({
                                       activeItem,
                                       setActiveItem,
                                       isExpanded,
                                       setIsExpanded,
                                       onClick,
                                       ...item
                                   }) => {
    const {topPosition, optionListHeight, itemRef, toggleOptions} = useItem(
        activeItem,
        setActiveItem,
        isExpanded,
        setIsExpanded,
        item
    );
    const theme = useThemeWhiteLabel();
    const [isIconRotated, setIsIconRotated] = useState(false);
    const handleToggleOptions = () => {
        toggleOptions();
        if (item.iconName === "chevron-right") {
            setIsIconRotated(!isIconRotated);
        }
    };

    const IconComponent = MATERIAL_ICONS[item.iconName];
    if (!item.options?.length && item.iconName != 'logout') return null
    return (
        <>
            <StyledDiv
                ref={itemRef}
                backgroundColor={item.backgroundColor || Palette.darkGrey2}
                onClick={handleToggleOptions}
                style={{
                    width: isExpanded ? "251px" : "auto",
                }}
                onClickCapture={onClick}
            >
                <InnerContainer>
                    <IconComponent
                        style={{
                            color: item.iconColor || theme.alternativeColor,
                            transform:
                                item.iconName === "chevron-right" && isIconRotated
                                    ? "rotate(180deg)"
                                    : "none",
                            transition: "transform 0.3s ease-in-out",
                        }}
                    />
                    {isExpanded && (
                        <Typography variant="h1" color="white">
                            {item.label}
                        </Typography>
                    )}
                </InnerContainer>
            </StyledDiv>
            {activeItem === item.iconName
                && item.options
                && (
                <OptionListContainer top={topPosition} offset={5}>
                    <OptionList options={item.options}/>
                </OptionListContainer>
            )}
        </>
    );
};

export default Item;
