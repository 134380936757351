import {
    InstructionsText,
    InstructionsTextBold,
    ScreenWithSubHeaderContainer,
} from "../../../../theme/GlobalStyles";
import { Button } from "@mui/material";
import {
    CameraAltOutlined as CameraIcon,
    UploadOutlined as UploadIcon,
} from "@mui/icons-material";
import EPhotoSourceType from "../../../../shared/models/backend/inquiry/EPhotoSourceType";
import { Palette } from "../../../../shared/theme/palette";
import { StyledDivButton } from "../../../onBoardingVideo/onBoardingVideo/styles";

interface ChoosePhotoSourceProps {
    source: EPhotoSourceType;
    setSource: (source: EPhotoSourceType) => void;
    onBack: () => void;
    onNext: () => void;
}

const ChoosePhotoSource = ({ ...props }: ChoosePhotoSourceProps) => {
    return (
        <>
          <ScreenWithSubHeaderContainer>
                <InstructionsTextBold>
                    {"Tipo de envio dos documentos"}
                </InstructionsTextBold>
                <InstructionsText>
                    {"Selecione abaixo a forma de envio dos documentos."}
                </InstructionsText>
                <>
                    <Button
                        style={styles.button}
                        onClick={() => {
                            props.setSource(EPhotoSourceType.Camera);
                            props.onNext();
                        }}
                        size={"small"}
                        startIcon={<CameraIcon />}
                        variant={"outlined"}
                    >
                        {"Tirar foto via camera do dispositivo"}
                    </Button>
                    <Button
                        style={styles.button}
                        onClick={() => {
                            props.setSource(EPhotoSourceType.File);
                            props.onNext();
                        }}
                        size={"small"}
                        startIcon={<UploadIcon />}
                        variant={"outlined"}
                    >
                        {"Enviar arquivo do computador"}
                    </Button>
                </>
                <StyledDivButton>
                    <Button onClick={props.onBack} variant={"text"}>
                        {"Voltar"}
                    </Button>
                </StyledDivButton>
                </ScreenWithSubHeaderContainer>
        </>
    );
};

const styles = {
    button: { width: 300, height: 48 },
};

export default ChoosePhotoSource;
