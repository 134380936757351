import { Grid, Stack } from "@mui/material";
import { MailingContractDataProps } from "..";
import MailingContractCheckbox from "../../ContractCheckbox";
import { ContractSectionTitle, ContractText } from "../styles";

const PersonalDataContractSection = ({
  mailingData,
}: MailingContractDataProps) => {
  return (
    <>
      <Grid item xs={12}>
        <ContractSectionTitle>I - DADOS PESSOAIS</ContractSectionTitle>
      </Grid>
      <Grid item xs={12}>
        <ContractText>
          Nome Completo (sem abreviações): {mailingData?.nome}
        </ContractText>
      </Grid>
      <Grid item xs={6}>
        <ContractText>CPF: {mailingData?.cpf}</ContractText>
      </Grid>
      <Grid item xs={6}>
        <ContractText>
          Número do Benefício: {mailingData?.numeroBeneficio}
        </ContractText>
      </Grid>
      <Grid item xs={4}>
        <ContractText>
          Nome do Representante Legal/Procurador/Terceiro:
        </ContractText>
      </Grid>
      <Grid item xs={4}>
        <ContractText>Condição informada por:</ContractText>
        <Stack direction="row" gap="16px">
          <MailingContractCheckbox label="INSS" checked />
          <MailingContractCheckbox label="Representante/Procurador/Terceiro" />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <ContractText>CPF:</ContractText>
      </Grid>
      <Grid item xs={12}>
        <ContractText>
          OS PRODUTOS E SERVIÇOS ABAIXO RELACIONADOS SÃO DE CONTRATAÇÃO
          OPCIONAL, A EXCLUSIVO CRITÉRIO DO CLIENTE, E AINDA ESTARÃO SUJEITOS À
          APROVAÇÃO DO BANCO CREFISA S/A[1] OU DA CREFISA S/A CRÉDITO,
          FINANCIAMENTO E INVESTIMENTOS[2]. CASO O CLIENTE OPTE PELA
          CONTRATAÇÃO, DEVERÁ SER ASSINALADO O QUADRANTE LOCALIZADO AO LADO
          ESQUERDO DO PRODUTO RELACIONADO. O CLIENTE AUTORIZA O BANCO CREFISA
          S/A E A CREFISA S/A CRÉDITO, FINANCIAMENTO E INVESTIMENTOS A REALIZAR
          O ENVIO DE INFORMAÇÕES E OFERTA DE PRODUTOS POR E-MAIL, SMS OU
          WHATSAPP.
        </ContractText>
        <Stack direction="row" gap="16px">
          <MailingContractCheckbox label="SIM" checked />
          <MailingContractCheckbox label="NÃO" />
        </Stack>
      </Grid>
    </>
  );
};

export default PersonalDataContractSection;
