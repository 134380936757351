import React, { useContext, useEffect, useState } from "react";
import CheckBox from "../../../../components/CheckBox";
import PropTypes from "prop-types";
import InquiryPriceAndBalanceCards from "../../components/InquiryPriceAndBalanceCards";
import { Box } from "@mui/material";
import Typography from "../../../../components/Typography";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

const OnBoardingType = ({ onNext, setPresential, inquiryPrice, presential }) => {
  const [inLive, setInlive] = useState(false);
  const [remote, setRemote] = useState(false);

  const handleWhatsAppCheck = () => {
    setRemote(!remote);
    if (!remote) {
      setInlive(false);
    }
    setPresential(remote ? null : false);
    onNext();
  };
  const handlePresentialCheck = () => {
    setInlive(!inLive);
    if (!inLive) {
      setRemote(false);
    }
    setPresential(inLive ? null : true);
    onNext();
  };
  const theme = useThemeWhiteLabel();
  return (
    <div>
      <InquiryPriceAndBalanceCards inquiryPrice={inquiryPrice} />
      <Box mt={3} mb={5}>
        <Typography variant="caption" >Para Iniciar o processo de onboarding de vídeo, selecione o tipo a ser realizado:</Typography>
        <Typography variant="caption3" >Selecione o Tipo</Typography>
      </Box>
      <Box mt={2} mb={2}>
        <CheckBox
          primaryColor={theme.primaryColor}
          label={"Onboarding por WhatsApp"}
          onPress={handleWhatsAppCheck}
          isChecked={remote}
        />
      </Box>
      <Box mb={2}>
        <CheckBox
          primaryColor={theme.primaryColor}
          label={"Onboarding Presencial"}
          onPress={handlePresentialCheck}
          isChecked={inLive}
        />
      </Box>
    </div>
  );
};
OnBoardingType.propTypes = {
  onNext: PropTypes.func.isRequired,
  setPresential: PropTypes.func.isRequired,
  inquiryPrice: PropTypes.number.isRequired,
  presential: PropTypes.bool.isRequired,
  whatsapp: PropTypes.bool.isRequired,
};

export default OnBoardingType;