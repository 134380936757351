import React, { useState } from "react";
import styled from 'styled-components';
import { MenuOption } from "./menuOption";
import Option from "./option";
import {useNavigate} from "react-router-dom";

interface OptionListProps {
  options: MenuOption[];
}

const OptionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10000;
  position: sticky;
`;

const OptionList: React.FC<OptionListProps> = ({ options }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  return (
    <OptionListContainer>
      {options.map((option, index) => (
        <Option key={index}
                iconName={option.iconName}
                text={option.text}
                onClick={() => option?.route && navigate(option.route)} 
                multiOption={option.multiOption}
                navigate={navigate}
                isOpen={openIndex === index}
                onToggle={() => setOpenIndex(openIndex === index ? null : index)}
                />
      ))}
    </OptionListContainer>
  );
};

export default OptionList;