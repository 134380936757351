import styled from "styled-components";
import {Palette} from "../../shared/theme/palette";
export const DragAndDropFileInputForm = styled.form`
    height: 16rem;
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
`;

export const DragAndDropFileInput = styled.input`
    display: none;
`;
export const DragAndDropFileInputLabel = styled.label<{ primaryColor: string }>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 3px;
    border-radius: 10px;
    border-style: dotted;
    gap: 1rem;
    border-color: ${(props) => props.primaryColor};
    background-color: ${Palette.lightGrey14};
`;

export const DragAndDropFileInputButton = styled.button<{ primaryColor: string }>`
    cursor: pointer;
    font-size: 14px;
    border: none;
    font-weight: 700;
    color: ${(props) => props.primaryColor};
    font-family: 'Inter', sans-serif;
    background-color: transparent;
    text-decoration-line: underline;
`;
export const DragFileElement = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`
export const DragAndDropFilePickerVerticalContainer = styled.div`
    flex-direction: column;
`;
export const DragAndDropFilePickerTextButton = styled.span`
    cursor: pointer;
    font-size: 14px;
    border: none;
    font-weight: 400;
    color: ${Palette.darkGrey2};
    font-family: 'Inter', sans-serif;
    background-color: transparent;
`;
export const DragAndDropFilePickerText = styled.span`
    cursor: pointer;
    font-size: 12px;
    border: none;
    font-weight: 400;
    color: ${Palette.darkGrey2};
    font-family: 'Inter', sans-serif;
    background-color: transparent;
`;