import { Button, FormControl, Grid, Radio, Stack } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GlobalLoader } from "../../../components/GlobalLoader/GlobalLoader";
import Input from "../../../components/Input/Input";
import { StyledCircularProgress } from "../../../components/Loading/styles";
import { useCrefisaWhiteLabelData } from "../../../hooks/useCrefisaWhiteLabelData";
import { useThemeWhiteLabel } from "../../../hooks/useThemeWhiteLabel";
import { MailingResponseData } from "../../../shared/models/backend/mailing/mailing";
import useMailingOnBoardingService from "../../../shared/services/useMailingOnBoardingService";
import { ValidationUtils } from "../../../utils/validation-utils";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";
import {
  Bold500Typography,
  BoldTypography,
  CardLowRadiusTitleContainer,
  InlineRadioGroup,
  InterTextTypography,
  LineDecorator,
  OnboardingCardLowRadius,
  PoppinsSmallBoldTypography,
  RadioLabel,
  SubTitleInterTypography,
  SubTitleLargeTypography,
  TitleExtraBoldTypography,
} from "../styles";
import ReviewClientData from "./ReviewClientData";
import { useClientDataForm } from "./useClientDataForm";

const ClientDataStep = ({
  setActiveStep,
  setPhotoToEdit,
}: {
  setPhotoToEdit: Dispatch<SetStateAction<"front" | "back">>;
} & MailingStepProps) => {
  const { id } = useParams();
  const theme = useThemeWhiteLabel();
  const [isReviewing, setIsReviewing] = useState(false);
  const [mailingData, setMailingData] = useState<MailingResponseData>(null);
  const { getMailing } = useMailingOnBoardingService();
  const {
    form,
    setForm,
    formErrors,
    setFormErrors,
    getFormInitialValues,
    validateForm,
    getChangedData,
    isFormValid,
  } = useClientDataForm();
  const [isLoading, setIsLoading] = useState(false);
  // const { whitelabelLogo, whitelabelName } = useWhitelabel();
  const { logo, name } = useCrefisaWhiteLabelData();
  const { sendMailingUserData } = useMailingOnBoardingService();

  useEffect(() => {
    if (!id) return;
    getMailing(id).then((response) => {
      setMailingData(response);
      if (response?.MailingUserData) {
        setIsReviewing(true);
      }
      setForm(
        getFormInitialValues(response.MailingData, response.MailingUserData)
      );
    });
  }, [id]);

  const handleSaveAndContinue = () => {
    setIsLoading(true);
    const changedData = getChangedData(
      mailingData?.MailingData,
      mailingData?.MailingUserData
    );
    if (Object.keys(changedData).length === 0) {
      setActiveStep(EMailingStepsEnum.ContractReading);
      return;
    }
    sendMailingUserData(id, changedData)
      .then(() => {
        setIsReviewing(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isFormValid = validateForm();

    if (isFormValid) {
      handleSaveAndContinue();
    }
  };

  if (!mailingData)
    return (
      <GlobalLoader
        logo={logo}
        alt={`${name}-logo`}
        color={theme.primaryColor}
        isFixed
      />
    );

  if (isReviewing)
    return (
      <ReviewClientData
        setActiveStep={setActiveStep}
        additionalInfo={mailingData.additionalInfo}
        mailingData={mailingData.MailingData}
        userData={form}
        onBack={() => setIsReviewing(false)}
        setPhotoToEdit={setPhotoToEdit}
      />
    );

  return (
    <ContainerContent>
      <Stack component="form" onSubmit={handleSubmit} width="100%">
        <OnboardingCardLowRadius sx={{ padding: "24px !important" }}>
          <LineDecorator style={{ backgroundColor: theme.primaryColor }} />
          <TitleExtraBoldTypography
            variant="h1"
            color={theme.primaryColor}
            align="center"
          >
            Dados de Identificação
          </TitleExtraBoldTypography>

          <Stack gap="8px" sx={{ width: "100%", marginTop: "-16px" }}>
            <InterTextTypography>
              Nome:&nbsp;
              <Bold500Typography>
                {mailingData?.MailingData?.nome}
              </Bold500Typography>
            </InterTextTypography>
            <InterTextTypography>
              CPF:&nbsp;
              <Bold500Typography>
                {mailingData?.MailingData?.cpf}
              </Bold500Typography>
            </InterTextTypography>
            <InterTextTypography>
              Número do benefício:&nbsp;
              <Bold500Typography>
                {mailingData?.MailingData?.numeroBeneficio}
              </Bold500Typography>
            </InterTextTypography>
          </Stack>
        </OnboardingCardLowRadius>

        <OnboardingCardLowRadius
          sx={{ marginBlock: "32px", padding: "32px !important" }}
        >
          <Stack width="100%">
            <SubTitleInterTypography mb="32px">
              Edite os dados abaixo, caso seja necessário.
            </SubTitleInterTypography>
          </Stack>
          <Grid container spacing="80px">
            <Grid item xs={12} md={6}>
              <SubTitleLargeTypography sx={{ color: theme.primaryColor }}>
                Dados pessoais
              </SubTitleLargeTypography>
              <Input
                fullWidth
                label="Telefone"
                isBigger
                required
                value={form?.phone}
                // error={formErrors?.phone}
                // hasError={!!formErrors?.phone}
                validationCallback={ValidationUtils.validatePhone}
                onChange={(phone) => {
                  setForm({ ...form, phone });
                  if (!phone) {
                    setFormErrors({
                      ...formErrors,
                      phone: "Campo obrigatório.",
                    });
                    return;
                  }
                  if (!ValidationUtils.validatePhone(phone)) {
                    setFormErrors({
                      ...formErrors,
                      phone: "Campo telefone é inválido.",
                    });
                    return;
                  }
                  setFormErrors({ ...formErrors, phone: undefined });
                }}
                mask="(99) 99999-9999"
              />
              <Input
                fullWidth
                label="E-mail"
                isBigger
                value={form?.email}
                // error={formErrors?.email}
                // hasError={!!formErrors?.email}
                validationCallback={ValidationUtils.validateEmail}
                onChange={(email) => {
                  setForm({ ...form, email });
                  if (!email) {
                    setFormErrors({
                      ...formErrors,
                      email: "Campo obrigatório.",
                    });
                    return;
                  }
                  if (!ValidationUtils.validateEmail(email)) {
                    setFormErrors({
                      ...formErrors,
                      email: "Campo email é inválido.",
                    });
                    return;
                  }
                  setFormErrors({ ...formErrors, email: undefined });
                }}
              />
              <Input
                fullWidth
                label="Nome da Mãe"
                isBigger
                value={form?.nomeMae}
                onChange={(nomeMae) => setForm({ ...form, nomeMae })}
              />
              <Input
                fullWidth
                label="Nome do Pai"
                isBigger
                value={form?.nomePai}
                onChange={(nomePai) => setForm({ ...form, nomePai })}
              />
              <Input
                fullWidth
                label="Escolaridade"
                isBigger
                value={form?.escolaridade}
                onChange={(escolaridade) => setForm({ ...form, escolaridade })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SubTitleLargeTypography sx={{ color: theme.primaryColor }}>
                Endereço
              </SubTitleLargeTypography>
              <Input
                fullWidth
                label="Rua"
                isBigger
                value={form?.logradouro}
                // error={formErrors?.logradouro}
                // hasError={!!formErrors?.logradouro}
                onChange={(logradouro) => {
                  setForm({ ...form, logradouro });
                  if (!logradouro) {
                    setFormErrors({
                      ...formErrors,
                      logradouro: "Campo obrigatório.",
                    });
                    return;
                  }
                  setFormErrors({ ...formErrors, logradouro: undefined });
                }}
              />
              <Stack
                direction={{ xs: "column", sm: "row" }}
                gap={{ xs: "0px", sm: "16px" }}
              >
                <Input
                  fullWidth
                  label="Número"
                  isBigger
                  value={form?.numero}
                  // error={formErrors?.numero}
                  // hasError={!!formErrors?.numero}
                  onChange={(numero) => {
                    setForm({ ...form, numero });
                    if (!numero) {
                      setFormErrors({
                        ...formErrors,
                        numero: "Campo obrigatório.",
                      });
                      return;
                    }
                    setFormErrors({ ...formErrors, numero: undefined });
                  }}
                />
                <Input
                  fullWidth
                  label="Complemento"
                  isBigger
                  value={form?.complemento}
                  // error={formErrors?.complemento}
                  // hasError={!!formErrors?.complemento}
                  onChange={(complemento) => {
                    setForm({ ...form, complemento });
                    if (!complemento) {
                      setFormErrors({
                        ...formErrors,
                        complemento: "Campo obrigatório.",
                      });
                      return;
                    }
                    setFormErrors({ ...formErrors, complemento: undefined });
                  }}
                />
              </Stack>
              <Input
                fullWidth
                label="Bairro"
                isBigger
                value={form?.bairro}
                // error={formErrors?.bairro}
                // hasError={!!formErrors?.bairro}
                onChange={(bairro) => {
                  setForm({ ...form, bairro });
                  if (!bairro) {
                    setFormErrors({
                      ...formErrors,
                      bairro: "Campo obrigatório.",
                    });
                    return;
                  }
                  setFormErrors({ ...formErrors, bairro: undefined });
                }}
              />
              <Input
                fullWidth
                label="Município"
                isBigger
                value={form?.cidade}
                // error={formErrors?.cidade}
                // hasError={!!formErrors?.cidade}
                onChange={(cidade) => {
                  setForm({ ...form, cidade });
                  if (!cidade) {
                    setFormErrors({
                      ...formErrors,
                      cidade: "Campo obrigatório.",
                    });
                    return;
                  }
                  setFormErrors({ ...formErrors, cidade: undefined });
                }}
              />

              <Stack
                direction={{ xs: "column", sm: "row" }}
                gap={{ xs: "0px", sm: "16px" }}
              >
                <Input
                  fullWidth
                  label="CEP"
                  isBigger
                  value={form?.cep}
                  // error={formErrors?.cep}
                  // hasError={!!formErrors?.cep}
                  onChange={(cep) => {
                    setForm({ ...form, cep });
                    if (!cep) {
                      setFormErrors({
                        ...formErrors,
                        cep: "Campo obrigatório.",
                      });
                      return;
                    }
                    setFormErrors({ ...formErrors, cep: undefined });
                  }}
                />
                <Input
                  fullWidth
                  label="UF"
                  isBigger
                  value={form?.ufEndereco}
                  // error={formErrors?.ufEndereco}
                  // hasError={!!formErrors?.ufEndereco}
                  maxLength={2}
                  onChange={(ufEndereco) => {
                    setForm({
                      ...form,
                      ufEndereco: ufEndereco.replace(/[0-9]/g, ""),
                    });
                    if (!ufEndereco) {
                      setFormErrors({
                        ...formErrors,
                        ufEndereco: "Campo obrigatório.",
                      });
                      return;
                    }
                    setFormErrors({ ...formErrors, ufEndereco: undefined });
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </OnboardingCardLowRadius>

        <OnboardingCardLowRadius sx={{ padding: "32px !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <SubTitleLargeTypography sx={{ color: theme.primaryColor }}>
                Dados do documento de identificação
              </SubTitleLargeTypography>
            </Grid>
            <Grid item xs={12} md={6} pr={{ xs: "0px", md: "40px" }}>
              <Input
                fullWidth
                label="Número do documento de identificação utilizado"
                isBigger
                value={form?.numeroDocumento}
                // error={formErrors?.numeroDocumento}
                // hasError={!!formErrors?.numeroDocumento}
                onChange={(numeroDocumento) => {
                  setForm({ ...form, numeroDocumento });
                  if (!numeroDocumento) {
                    setFormErrors({
                      ...formErrors,
                      numeroDocumento: "Campo obrigatório.",
                    });
                    return;
                  }
                  setFormErrors({ ...formErrors, numeroDocumento: undefined });
                }}
              />
              <Input
                fullWidth
                label="UF emissor"
                isBigger
                value={form?.ufEmissor}
                maxLength={2}
                // error={formErrors?.ufEmissor}
                // hasError={!!formErrors?.ufEmissor}
                onChange={(ufEmissor) => {
                  setForm({
                    ...form,
                    ufEmissor: ufEmissor.replace(/[0-9]/g, ""),
                  });
                  if (!ufEmissor) {
                    setFormErrors({
                      ...formErrors,
                      ufEmissor: "Campo obrigatório.",
                    });
                    return;
                  }
                  setFormErrors({ ...formErrors, ufEmissor: undefined });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} pl={{ xs: "0px", md: "40px" }}>
              <Input
                fullWidth
                label="Órgão emissor"
                isBigger
                value={form?.orgaoEmissor}
                // error={formErrors?.orgaoEmissor}
                // hasError={!!formErrors?.orgaoEmissor}
                onChange={(orgaoEmissor) => {
                  setForm({
                    ...form,
                    orgaoEmissor: orgaoEmissor.replace(/[0-9]/g, ""),
                  });
                  if (!orgaoEmissor) {
                    setFormErrors({
                      ...formErrors,
                      orgaoEmissor: "Campo obrigatório.",
                    });
                    return;
                  }
                  setFormErrors({ ...formErrors, orgaoEmissor: undefined });
                }}
              />
              <Input
                fullWidth
                label="Data da emissão"
                isBigger
                value={form?.dataEmissao}
                // error={formErrors?.dataEmissao}
                // hasError={!!formErrors?.dataEmissao}
                mask="99/99/9999"
                onChange={(dataEmissao) => {
                  setForm({ ...form, dataEmissao });
                  if (!dataEmissao) {
                    setFormErrors({
                      ...formErrors,
                      dataEmissao: "Campo obrigatório.",
                    });
                    return;
                  }
                  setFormErrors({ ...formErrors, dataEmissao: undefined });
                }}
              />
            </Grid>
          </Grid>

          <CardLowRadiusTitleContainer
            style={{ backgroundColor: theme.primaryColor }}
          >
            <SubTitleLargeTypography>
              Solicitação de Cartão de Crédito
            </SubTitleLargeTypography>
          </CardLowRadiusTitleContainer>
          <Stack width="100%">
            <PoppinsSmallBoldTypography>
              Deseja solicitar um cartão de crédito?
            </PoppinsSmallBoldTypography>
            <FormControl>
              <InlineRadioGroup
                value={form?.cardAcquired}
                onChange={(event) => {
                  setForm({
                    ...form,
                    cardAcquired: event.target.value as any,
                    cardExpirationDate: "" as any,
                  });
                  setFormErrors((current) => ({
                    ...current,
                    cardAcquired:
                      event.target.value === "no" ? undefined : "error",
                  }));
                }}
              >
                <RadioLabel
                  value="yes"
                  control={<Radio size="small" />}
                  label="Sim"
                />
                <RadioLabel
                  value="no"
                  control={<Radio size="small" />}
                  label="Não"
                />
              </InlineRadioGroup>
              {/* {formErrors?.cardAcquired && form.cardAcquired === "" && (
                <Typography color="error" fontSize="12px">
                  Campo obrigatório.
                </Typography>
              )} */}
            </FormControl>

            {form?.cardAcquired === "yes" && (
              <>
                <PoppinsSmallBoldTypography mt="32px">
                  Vencimento do cartão
                </PoppinsSmallBoldTypography>
                <FormControl>
                  <InlineRadioGroup
                    value={form.cardExpirationDate}
                    name="solicitacao-credito"
                    onChange={(event) => {
                      setForm((current) => ({
                        ...current,
                        cardExpirationDate: event.target.value as any,
                      }));
                      setFormErrors((current) => ({
                        ...current,
                        cardAcquired: undefined,
                      }));
                    }}
                  >
                    <Stack>
                      <RadioLabel
                        value={1}
                        control={<Radio size="small" />}
                        label="1"
                      />
                      <RadioLabel
                        value={5}
                        control={<Radio size="small" />}
                        label="5"
                      />
                    </Stack>
                    <Stack>
                      <RadioLabel
                        value={10}
                        control={<Radio size="small" />}
                        label="10"
                      />
                      <RadioLabel
                        value={15}
                        control={<Radio size="small" />}
                        label="15"
                      />
                    </Stack>
                    <Stack>
                      <RadioLabel
                        value={20}
                        control={<Radio size="small" />}
                        label="20"
                      />
                      <RadioLabel
                        value={25}
                        control={<Radio size="small" />}
                        label="25"
                      />
                    </Stack>
                  </InlineRadioGroup>
                  {/* {formErrors?.cardAcquired && form.cardAcquired === "yes" && (
                    <Typography color="error" fontSize="12px">
                      Campo obrigatório.
                    </Typography>
                  )} */}
                </FormControl>
              </>
            )}
          </Stack>
        </OnboardingCardLowRadius>

        <SubTitleInterTypography textAlign="center" mt="40px">
          Após revisar os dados, clique no botão{" "}
          <BoldTypography>“Continuar”</BoldTypography> para ver o resumo dos
          dados.
        </SubTitleInterTypography>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          gap="24px"
          mt="48px"
        >
          <Button
            color="primary"
            variant="outlined"
            size="large"
            disabled={isLoading}
            onClick={() => setActiveStep(EMailingStepsEnum.VideoRecording)}
          >
            Voltar
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            disabled={isLoading || !isFormValid}
            startIcon={
              isLoading ? <StyledCircularProgress size={16} /> : undefined
            }
            sx={{ paddingInline: "24px" }}
          >
            Continuar
          </Button>
        </Stack>
      </Stack>
    </ContainerContent>
  );
};

export default ClientDataStep;
