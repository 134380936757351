import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import { useContext, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./components/Loading/loading";
import Startup from "./components/Startup";
import { ApiConfig } from "./constants/ApiConfig";
import Routes from "./constants/Routes";
import "./fonts/fonts.css";
import AppRoutes from "./navigation/AppRoutes";
import {
  AccountContext,
  AccountContextProvider,
} from "./shared/context/AccountContext";
import ConsultaiAppContext from "./shared/services/context/consultai-app-context";
import { ConsultaiApiContextProvider } from "./shared/services/context/providers/consultai-api-context-provider";
import { ConsultaiAppContextProvider } from "./shared/services/context/providers/consultai-app-provider";
import { PagarMeApiContextProvider } from "./shared/services/context/providers/pagarme-api-context-provider";
import MaterialUiThemeProvider from "./theme/material-ui-theme";

const App = () => {
  const account = useContext(AccountContext);
  useEffect(() => {
    if (window.self !== window.top) window.top.location = window.location.href;
  }, []);

  const { pathname } = window.location;

  return (
    <>
      <AccountContextProvider>
        <ConsultaiAppContextProvider>
          <ConsultaiApiContextProvider
            env={ApiConfig.Environment!}
            baseUrl={ApiConfig.Consultai.baseURL!}
            enableLogs={ApiConfig.LogsEnabled}
            onError={(error) => toast.error(error)}
            onWarn={(warn) => toast.warn(warn)}
            onInfo={(info) => toast(info)}
            onLogout={() => {
              toast.error("Sessão expirada, faça login novamente");
              account.setIds({ usuarioId: null, unitId: null });
              window.location.replace(Routes.Login);
            }}
          >
            <PagarMeApiContextProvider
              env={ApiConfig.Environment!}
              baseUrl={ApiConfig.Pagarme.baseURL!}
              enableLogs={ApiConfig.LogsEnabled}
              onError={(error) => toast.error(error)}
              onWarn={(warn) => toast.warn(warn)}
              onInfo={(info) => toast(info)}
              basicToken={ApiConfig.Pagarme.basicToken!}
            >
              <MaterialUiThemeProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="pt-br"
                >
                  <CssBaseline />
                  <RouterProvider router={AppRoutes} />
                  <Startup />
                  <ConsultaiAppContext.Consumer>
                    {(consultaiAppContext) => (
                      <>
                        {consultaiAppContext?.loading &&
                          !pathname.includes("/mailing/") && <Loading />}
                      </>
                    )}
                  </ConsultaiAppContext.Consumer>
                </LocalizationProvider>
              </MaterialUiThemeProvider>
            </PagarMeApiContextProvider>
          </ConsultaiApiContextProvider>
        </ConsultaiAppContextProvider>
      </AccountContextProvider>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        theme="colored"
      />
    </>
  );
};

export default App;
