import React, {ReactNode, useContext, useEffect, useState} from 'react';
import Menu from '../components/Menu/Menu';
import Header from '../components/Header/Header';
import {LayoutContainer} from "./styles";
import {AccountContext} from "../shared/context/AccountContext";
import {useNavigate} from "react-router-dom";
import Routes from "../constants/Routes";

type LayoutProps = {
    children: ReactNode;
    iconHelp?: boolean
};

const Layout: React.FC<LayoutProps> = ({children, iconHelp = true}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const account = useContext(AccountContext)
    const navigate = useNavigate()
    useEffect(() => {
        if (!account.ids.unitId) navigate(Routes.Login)
    }, [account])
    return (
        <>
            <Header iconHelp={iconHelp}/>
            <Menu onExpandChange={setIsExpanded}/>
            <LayoutContainer
                style={{paddingLeft: isExpanded ? 240 : undefined}}>
                {children}
            </LayoutContainer>
        </>
    );
};

export default Layout;
