import React, {useEffect, useState} from "react";
import {
    DragAndDropFileInput,
    DragAndDropFileInputButton,
    DragAndDropFileInputForm,
    DragAndDropFileInputLabel,
    DragAndDropFilePickerText,
    DragAndDropFilePickerTextButton,
    DragAndDropFilePickerVerticalContainer,
    DragFileElement
} from "./styles";
import {CloudUpload as CloudUploadIcon, InsertDriveFileOutlined as FileCopyIcon} from '@mui/icons-material';
import {toast} from "react-toastify";
import FileInput from "../../shared/models/fileInput";
import { useThemeWhiteLabel } from "../../hooks/useThemeWhiteLabel";

interface DragAndDropFileInputProps {
    onFileChange: (files: FileInput[]) => void;
}

const DragAndDropFilePicker = ({...props}: DragAndDropFileInputProps) => {
    const [dragActive, setDragActive] = React.useState(false);
    const [files, setFiles] = useState<FileInput[]>([]);
    const [fileNames, setFileNames] = useState<string[]>([]);
    const theme = useThemeWhiteLabel();
    const inputRef = React.useRef(null);

    useEffect(() => {
        if (files.length > 0) props.onFileChange(files);
    }, [files]);

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handlePickImageChange(e.dataTransfer.files);
        }
    };

    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) handlePickImageChange(e.target.files);
    };

    const handlePickImageChange = (imgFiles) => {
        const newFiles = [];
        const newFileNames = [];
        for (let i = 0; i < imgFiles.length && i < 2; i++) {
            const imgFile = imgFiles[i];
            if (imgFile && isValidExtension(imgFile.name)) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const fileSize = (imgFile.size / 1000000);
                    if (fileSize > 10) {
                        toast.error(`O arquivo ${imgFile.name} excede o limite de 10mb.`);
                        return;
                    }
                    newFiles.push({fileName: imgFile.name, fileContent: reader.result as string});
                    newFileNames.push(`${imgFile.name} (${fileSize.toFixed(2)} MB)`);
                    if (newFiles.length === imgFiles.length || newFiles.length === 2) {
                        setFiles(newFiles);
                        setFileNames(newFileNames);
                    }
                };
                reader.readAsDataURL(imgFile);
            } else {
                toast.error("Por favor, selecione um arquivo de imagem válido (*.png, *.jpeg, *.jpg, *.pdf).");
            }
        }
    };

    const isValidExtension = (filename: string) =>
        filename.endsWith('.png')
        || filename.endsWith('.jpeg')
        || filename.endsWith('.jpg')
        || filename.endsWith('.pdf');

    return (
        <DragAndDropFileInputForm
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
        >
            <DragAndDropFileInput
                ref={inputRef}
                type="file"
                id="input-file-upload"
                multiple={true}
                onChange={handleChange}/>
            <DragAndDropFileInputLabel primaryColor={theme.primaryColor}
                htmlFor="input-file-upload"
                className={dragActive ? "drag-active" : ""}>
                <DragAndDropFilePickerVerticalContainer>
                    <DragAndDropFilePickerTextButton>
                        {files.length > 0 ? 'Arquivos anexados com sucesso.' : 'Solte seus anexos aqui ou '}
                    </DragAndDropFilePickerTextButton><br/>
                    {fileNames.map((fileName, index) => (
                        <DragAndDropFilePickerVerticalContainer key={index}>
                            <FileCopyIcon color={'primary'} fontSize={'small'}/>
                            <DragAndDropFilePickerText>
                                {fileName}
                            </DragAndDropFilePickerText>
                            <br/>
                        </DragAndDropFilePickerVerticalContainer>
                    ))}
                    <DragAndDropFileInputButton primaryColor={theme.primaryColor} onClick={() => inputRef.current.click()}>{
                        files.length > 0 ? 'Escolher outros arquivos' : 'selecione arquivos'
                    }</DragAndDropFileInputButton>
                    <br/>
                    {files.length === 0 && <CloudUploadIcon color={'primary'} style={{fontSize: 72}}/>}
                    <br/>
                    {files.length === 0 &&
                        <DragAndDropFilePickerText>
                            Somente arquivos JPG, PNG ou PDF.
                            A soma de todos os arquivos poderão ter no máximo <b>10mb</b>.
                        </DragAndDropFilePickerText>}
                </DragAndDropFilePickerVerticalContainer>
            </DragAndDropFileInputLabel>
            {dragActive &&
                <DragFileElement
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}/>}
        </DragAndDropFileInputForm>
    )
}

export default DragAndDropFilePicker;