import Layout from "../../../layout";
import React, { useEffect, useState } from "react";
import SubHeader from "../../../components/SubHeader/SubHeader";
import DocumentInquiryForm from "../components/DocumentInquiryForm";
import DocumentInquiryInstructions from "../components/DocumentInquiryInstructions";
import TakeDocumentPhoto from "../components/TakeDocumentPhoto";
import { DocumentType } from "../../../shared/models/document-type";
import InquirySentSuccessfully from "../components/InquirySentSuccessfully";
import useInquiryService from "../../../shared/services/useInquiryService";
import SendDocumentInquiryInput from "../../../shared/models/backend/inquiry/send-document-inquiry-input";
import EDocumentType from "../../../shared/models/backend/document/EDocumentType";
import { toast } from "react-toastify";
import EInquiryType from "../../../shared/models/backend/inquiry/EInquiryType";
import ChoosePhotoSource from "../components/ChoosePhotoSource";
import DocInquiryFileUpload from "../components/DocInquiryFileUpload";
import FileInput from "../../../shared/models/fileInput";
import EPhotoSourceType from "../../../shared/models/backend/inquiry/EPhotoSourceType";

export enum EDocumentInquiryStep {
    Cpf,
    ChoosePhotoSource,
    Instructions,
    FrontPhoto,
    BackPhoto,
    SelfiePhoto,
    SendFiles,
    Confirmation
}

interface DocumentInquiryScreenProps {
    withSelfie?: boolean;
    automatic?: boolean;
}


const DocumentInquiryScreen = ({ withSelfie, automatic }: DocumentInquiryScreenProps) => {
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = React.useState<EDocumentInquiryStep>(EDocumentInquiryStep.Cpf);
    const [cpf, setCpf] = useState('');
    const [docType, setDocType] = useState<DocumentType>();
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [frontPhoto, setFrontPhoto] = useState<FileInput>();
    const [backPhoto, setBackPhoto] = useState<FileInput>();
    const [selfiePhoto, setSelfiePhoto] = useState<FileInput>();
    const [sentInquiryId, setSentInquiryId] = useState<number>();
    const [photoSource, setPhotoSource] = useState<EPhotoSourceType>();
    const {
        createAndSendDocumentInquiryAsync,
        createAndSendDocumentWithSelfieInquiryAsync,
        createAndSendDocumentAutomaticInquiryAsync,
        createAndSendDocumentAutomaticWithSelfieInquiryAsync,
        saveInquiryFileAsync,
    } = useInquiryService();
    useEffect(() => {
        switch (currentStep) {
            case EDocumentInquiryStep.Cpf:
                setTitle('Consultas');
                setSubtitle(`Documento ${withSelfie ? (automatic ? '+ Selfie - Digital híbrida' : '+ Selfie - análise manual') : (automatic ? '- Digital híbrida' : '- Análise manual')}`);
                return
            case EDocumentInquiryStep.ChoosePhotoSource:
                setTitle('Tipo de envio');
                return
            case EDocumentInquiryStep.Instructions:
                setTitle('Instruções');
                return
            case EDocumentInquiryStep.FrontPhoto:
                setTitle(docType == 'rg' ? 'Tirar foto da frente do RG' : 'Tirar foto da CNH');
                return
            case EDocumentInquiryStep.BackPhoto:
                if (docType == 'cnh') setCurrentStep(EDocumentInquiryStep.SelfiePhoto)
                setTitle('Tirar foto do verso do RG');
                return
            case EDocumentInquiryStep.SelfiePhoto:
                if (!withSelfie) setCurrentStep(EDocumentInquiryStep.Confirmation)
                setTitle('Tirar selfie');
                return
            case EDocumentInquiryStep.SendFiles:
                setTitle('Envio de arquivos');
                return
            case EDocumentInquiryStep.Confirmation:
                setTitle('Confirmação');
                return
        }
    }, [currentStep]);

    const nextStep = () => {
        if (currentStep == EDocumentInquiryStep.SelfiePhoto
            || currentStep == EDocumentInquiryStep.BackPhoto && !withSelfie
            || docType == 'cnh' && currentStep == EDocumentInquiryStep.FrontPhoto && !withSelfie
            || currentStep == EDocumentInquiryStep.SendFiles
        ) {
            sendInquiry()
            return
        }
        if ((currentStep == EDocumentInquiryStep.Instructions) && photoSource == EPhotoSourceType.File) {
            setCurrentStep(EDocumentInquiryStep.SendFiles)
            return;
        }

        setCurrentStep(currentStep + 1)
    }
    const onChangePhoto = (fileName: string, fileContent: string, type: 'front' | 'back' | 'selfie') => {
        switch (type) {
            case 'front':
                setFrontPhoto({ fileContent, fileName });
                break;
            case 'back':
                setBackPhoto({ fileContent, fileName });
                break;
            case 'selfie':
                setSelfiePhoto({ fileContent, fileName });
                break;
        }
    }
    const previousStep = () => setCurrentStep(currentStep == EDocumentInquiryStep.FrontPhoto ? EDocumentInquiryStep.Cpf : currentStep - 1)
    const resetForms = (redirect = true) => {
        setCpf(undefined);
        setDocType(undefined);
        setFrontPhoto(undefined);
        setBackPhoto(undefined);
        setSelfiePhoto(undefined);
        redirect && setCurrentStep(EDocumentInquiryStep.Cpf)
    };
    const sendInquiry = async () => {
        setLoading(true);
        try {
            const isCNH = docType === 'cnh';

            const inquiryPayload: SendDocumentInquiryInput = {
                documentType: isCNH ? EDocumentType.CNH : EDocumentType.RG,
                cpf,
                frontPhoto: await saveInquiryFileAsync(frontPhoto),
                backPhoto: backPhoto ? await saveInquiryFileAsync(backPhoto) : null,
                selfiePhoto: selfiePhoto ? await saveInquiryFileAsync(selfiePhoto) : null,
            };

            let result;
            switch (true) {
                case withSelfie && automatic:
                    result = await createAndSendDocumentAutomaticWithSelfieInquiryAsync(inquiryPayload);
                    break;
                case withSelfie && !automatic:
                    result = await createAndSendDocumentWithSelfieInquiryAsync(inquiryPayload);
                    break;
                case !withSelfie && automatic:
                    result = await createAndSendDocumentAutomaticInquiryAsync(inquiryPayload);
                    break;
                case !withSelfie && !automatic:
                    result = await createAndSendDocumentInquiryAsync(inquiryPayload);
                    break;
            }

            toast.success('Consulta enviada com sucesso!');
            resetForms(false);
            setSentInquiryId(result.id);
            setCurrentStep(EDocumentInquiryStep.Confirmation);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Layout>
            <SubHeader
                title={title}
                subtitle={subtitle} />
            {currentStep == EDocumentInquiryStep.Cpf &&
                <DocumentInquiryForm
                    cpf={cpf}
                    setCpf={setCpf}
                    docType={docType}
                    setDocType={setDocType}
                    onNext={nextStep}
                    loading={loading}
                    setLoading={setLoading}
                    withSelfie={withSelfie}
                />}
            {currentStep == EDocumentInquiryStep.ChoosePhotoSource &&
                <ChoosePhotoSource
                    source={photoSource}
                    setSource={setPhotoSource}
                    onBack={previousStep}
                    onNext={nextStep}
                />}
            {currentStep == EDocumentInquiryStep.Instructions &&
                <DocumentInquiryInstructions
                    source={photoSource}
                    docType={docType}
                    withSelfie={withSelfie}
                    onBack={previousStep}
                    onNext={nextStep} />}

            {currentStep == EDocumentInquiryStep.FrontPhoto &&
                photoSource == EPhotoSourceType.Camera &&
                < TakeDocumentPhoto
                    currentStep={currentStep}
                    photoType={'front'}
                    docType={docType}
                    withSelfie={withSelfie}
                    loading={loading}
                    onBack={previousStep}
                    onChangePhoto={onChangePhoto}
                    onNext={nextStep} />}
            {currentStep == EDocumentInquiryStep.BackPhoto &&
                photoSource == EPhotoSourceType.Camera &&
                <TakeDocumentPhoto
                    currentStep={currentStep}
                    photoType={'back'}
                    docType={docType}
                    withSelfie={withSelfie}
                    loading={loading}
                    onBack={previousStep}
                    onChangePhoto={onChangePhoto}
                    onNext={nextStep}
                />
            }
            {
                currentStep == EDocumentInquiryStep.SelfiePhoto &&
                photoSource == EPhotoSourceType.Camera &&
                <TakeDocumentPhoto
                    currentStep={currentStep}
                    photoType={'selfie'}
                    docType={docType}
                    withSelfie={withSelfie}
                    loading={loading}
                    onBack={previousStep}
                    onChangePhoto={onChangePhoto}
                    onNext={nextStep} />
            }
            {
                currentStep == EDocumentInquiryStep.SendFiles &&
                photoSource == EPhotoSourceType.File &&
                <DocInquiryFileUpload
                    withSelfie={withSelfie}
                    onBack={previousStep}
                    onNext={nextStep}
                    enableNext={!!frontPhoto && (withSelfie ? !!selfiePhoto : true)}
                    onChangePhoto={onChangePhoto}
                />
            }
            {
                currentStep == EDocumentInquiryStep.Confirmation &&
                <InquirySentSuccessfully
                    inquiryType={
                        withSelfie ? (automatic ? EInquiryType.DocumentoComSelfieAutomatic : EInquiryType.DocumentoComSelfie)
                            : (automatic ? EInquiryType.DocumentoAutomatic : EInquiryType.Documento)
                    }
                    inquiryId={sentInquiryId}
                    docType={docType}
                    withSelfie={withSelfie}
                    onNewInquiry={resetForms} />
            }
        </Layout>
    );
}
export default DocumentInquiryScreen;