import React, {useEffect, useState} from "react";
import {Button, Grid} from "@mui/material";
import Modal from "../../../../../../components/Modal";
import Input from "../../../../../../components/Input/Input";
import Typography from "../../../../../../components/Typography";
import PropTypes from "prop-types";
import {ButtonContainer} from "./styles";
import {FormatUtils} from "../../../../../../shared/utils/format-utils";
import {toast} from "react-toastify";
import {DateUtils} from "../../../../../../shared/utils/date-utils";
import {Contract} from "../../../../../../shared/models/backend/inquiry/payroll-loan-onboarding-request";

interface SetContractFunctions {
    setContractNumber: (value: string) => void;
    setValue: (value: number) => void;
    setInstallmentsValue: (value: number) => void;
    setInstallments: (value: number) => void;
    setBankName: (value: string) => void;
    setContractDate: (value: string) => void;
}

interface ModalContractProps extends Contract, SetContractFunctions {
    onClose: () => void;
    onSave: () => void;
}

const ModalContract = ({
                           onClose,
                           contractNumber,
                           setContractNumber,
                           value,
                           setValue,
                           installmentsValue,
                           setInstallmentsValue,
                           installments,
                           setInstallments,
                           bankName,
                           setBankName,
                           contractDate,
                           setContractDate,
                           onSave,
                       }: ModalContractProps) => {
    const [valueInput, setValueInput] = useState("");
    const [installmentsValueInput, setInstallmentsValueInput] = useState("");
    const [formattedValue, setFormattedValue] = useState("");
    const [formattedInstallmentsValue, setFormattedInstallmentsValue] =
        useState("");

    useEffect(() => {
        if (value) {
            setFormattedValue(FormatUtils.toBRLCurrency(value));
        }
    }, [value]);

    useEffect(() => {
        if (installmentsValue) {
            setFormattedInstallmentsValue(
                FormatUtils.toBRLCurrency(installmentsValue)
            );
        }
    }, [installmentsValue]);

    const handleSetValueInput = (value: string) => {
        setValueInput(value);
    };

    const handleSetInstallmentsValueInput = (value: string) => {
        setInstallmentsValueInput(value);
    };

    const handleBlurValue = () => {
        const numericValue = Number(valueInput.replace(/\D/g, ""));
        setValue(numericValue);
        const formattedValue = FormatUtils.toBRLCurrency(numericValue);
        setFormattedValue(formattedValue);
        setValueInput(formattedValue);
    };

    const handleBlurInstallmentsValue = () => {
        const numericInstallmentsValue = Number(
            installmentsValueInput.replace(/\D/g, "")
        );
        setInstallmentsValue(numericInstallmentsValue);
        const formattedInstallmentsValue = FormatUtils.toBRLCurrency(
            numericInstallmentsValue
        );
        setFormattedInstallmentsValue(formattedInstallmentsValue);
        setInstallmentsValueInput(formattedInstallmentsValue);
    };

    const handleSetInstallments = (value: string) => {
        setInstallments(Number(value));
    };
    const handleDateChange = (date: string) => {
        const selectedDate = DateUtils.parseDate(date);
        if (selectedDate > new Date()) {
            toast.error("A data de contratação não pode ser futura.");
            return;
        }
        setContractDate(date);
    };
    return (
        <Modal title="Adicionar novo contrato" onClose={onClose}>
            <>
                <Typography variant="caption">
                    Preencha os campos abaixo para enviar o link de acesso ao cliente.
                    Essas informações serão utilizadas no texto-guia que deverá ser lido
                    durante a gravação do vídeo de onboarding.
                </Typography>
                <Grid container spacing={3} alignContent={"center"}>
                    <Grid item xs={6}>
                        <Input
                            value={contractNumber}
                            onChange={setContractNumber}
                            placeholder="Número do contrato*"
                            width={"280px"}
                        ></Input>
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            value={valueInput}
                            onChange={handleSetValueInput}
                            onBlur={handleBlurValue}
                            placeholder="Valor do empréstimo*"
                            width={"280px"}
                        ></Input>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Input
                            value={installmentsValueInput}
                            onChange={handleSetInstallmentsValueInput}
                            onBlur={handleBlurInstallmentsValue}
                            placeholder="Valor da parcela*"
                            width={"280px"}
                        ></Input>
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            value={installments ? installments.toString() : ""}
                            onChange={handleSetInstallments}
                            placeholder="Quantidade de parcelas*"
                            width={"280px"}
                        ></Input>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Input
                            value={contractDate}
                            onChange={handleDateChange}
                            placeholder="Data da contratação*"
                            width={"280px"}
                            mask={"99/99/9999"}
                            maxLength={10}
                        ></Input>
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            value={bankName}
                            onChange={setBankName}
                            placeholder="Banco*"
                            width={"280px"}
                        ></Input>
                    </Grid>
                </Grid>
                <ButtonContainer>
                    <Button size="medium" color="secondary" onClick={onClose}>
                        Voltar
                    </Button>
                    <Button
                        variant="contained"
                        color={
                            value &&
                            installmentsValue &&
                            installments &&
                            bankName &&
                            contractDate
                                ? "primary"
                                : "inherit"
                        }
                        onClick={onSave}
                        disabled={
                            !value ||
                            !installmentsValue ||
                            !installments ||
                            !bankName ||
                            !contractDate
                        }
                    >
                        Adicionar contrato
                    </Button>
                </ButtonContainer>
            </>
        </Modal>
    );
};
ModalContract.propTypes = {
    onClose: PropTypes.func.isRequired,
    contractNumber: PropTypes.string.isRequired,
    setContractNumber: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    installmentsValue: PropTypes.string.isRequired,
    setInstallmentsValue: PropTypes.func.isRequired,
    installments: PropTypes.string.isRequired,
    setInstallments: PropTypes.func.isRequired,
    bankName: PropTypes.string.isRequired,
    setBankName: PropTypes.func.isRequired,
    contractDate: PropTypes.string.isRequired,
    setContractDate: PropTypes.func.isRequired,
};
export default ModalContract;
