import React, {useContext, useEffect, useState} from "react";
import {AccountContext} from "../../../../shared/context/AccountContext";
import {useSubscriptionService} from "../../../../shared/services/useSubscriptionService";
import {usePlanService} from "../../../../shared/services/usePlanService";
import EInquiryType from "../../../../shared/models/backend/inquiry/EInquiryType";
import {toast} from "react-toastify";
import {
    FormContainer,
    HorizontalButtonsContainer,
    InstructionsText,
    ScreenWithSubHeaderContainer
} from "../../../../theme/GlobalStyles";
import InquiryPriceAndBalanceCards from "../InquiryPriceAndBalanceCards";
import Input from "../../../../components/Input/Input";
import {Button} from "@mui/material";
import {ValidationUtils} from "../../../../utils/validation-utils";

interface EmailInquiryFormProps {
    onNext: () => void;
    email: string;
    setEmail: (cpf: string) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;

}
const EmailInquiryForm = ({...props}: EmailInquiryFormProps) => {
    const [inquiryPrice, setInquiryPrice] = useState<number>();
    const {ids} = useContext(AccountContext)
    const {buscarAssinaturaAtiva} = useSubscriptionService();
    const {getProductPrices} = usePlanService();
    useEffect(() => {
        props.setLoading(true)
        buscarAssinaturaAtiva(ids.unitId).then((subscription) => {
            getProductPrices(subscription.planoId).then((prices) => {
                const price = prices?.find((price) => price.type == EInquiryType.Email)?.price;
                setInquiryPrice(price)
                if (!price) toast.error('Seu plano atual não permite esse tipo de consulta.', {
                    autoClose: false,
                    closeButton: true,
                    toastId: 'no-price-error'
                })
            })
        })
            .finally(() => props.setLoading(false))
    }, [ids]);
    return (
        <>
            <ScreenWithSubHeaderContainer>
                <>
                    <InquiryPriceAndBalanceCards inquiryPrice={inquiryPrice}/>
                    <InstructionsText>{'Para realizar uma consulta por e-mail, basta preencher o campo abaixo.'}</InstructionsText>
                    <FormContainer>
                        <Input placeholder='Preencha o e-mail'
                               onChange={props.setEmail}
                               value={props.email}
                               required
                               validationCallback={ValidationUtils.validateEmail}
                               error={'E-mail inválido'}
                               label={'E-mail'}/>
                        <HorizontalButtonsContainer>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={props.onNext}
                                disabled={!inquiryPrice || props.loading || !props.email}
                            >
                                {'Solicitar Consulta'}
                            </Button>
                        </HorizontalButtonsContainer>
                    </FormContainer>
                </>
            </ScreenWithSubHeaderContainer>
        </>
    )
}
export default EmailInquiryForm;