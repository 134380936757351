import React from "react";
import OnBoardingType from "../components/OnboardingType";
import LinkSent from "../components/LinkSent";
import Layout from "../../../../layout";
import SubHeader from "../../../../components/SubHeader/SubHeader";
import {ScreenWithSubHeaderContainer} from "../../../../theme/GlobalStyles";
import FormPayrollLoanOnboarding from "./components/Form";
import {usePayrollLoanOnboarding} from "./UsePayrollLoanOnboarding";
import Contracts from "./components/contracts/Contracts";
import BankData from "./components/bankData";
import RepresentantForm from "./components/representativeForm";
import ConfirmData from "./components/confirmData";
import EInquiryType from "../../../../shared/models/backend/inquiry/EInquiryType";

const PayrollLoanVideoOnboarding = () => {
    const {
        onSendLink,
        name,
        setName,
        cpf,
        setCPF,
        phone,
        setPhone,
        birthDate,
        setbirthDate,
        setIlliterate,
        setlegalRepresentative,
        setRecipient,
        showForm,
        setShowForm,
        inquiryPrice,
        setPresential,
        presential,
        setStep,
        step,
        onNewOnboarding,
        onReturnMenu,
        contractNumber,
        setContractNumber,
        value,
        setValue,
        installmentsValue,
        setInstallmentsValue,
        installments,
        setInstallments,
        bankName,
        setBankName,
        contractDate,
        setContractDate,
        whatsapp,
        illiterate,
        legalRepresentative,
        recipient,
        contracts,
        setContracts,
        agency,
        setAgency,
        account,
        setAccount,
        bankNameData,
        setBankNameData,
        nameRepresentant,
        setNameRepresentant,
        cpfRepresentant,
        setCPFRepresentant,
        phoneRepresentant,
        setPhoneRepresentant,
        birthDateRepresentant,
        setbirthDateRepresentant,
        complementText,
        setcomplementText,
        replaceCurrentUnityComplementText,
        setReplaceCurrentUnityComplementText,
    } = usePayrollLoanOnboarding();

    let component;
    switch (step) {
        case 0:
            component = (
                <>
                    <OnBoardingType
                        onNext={() => {
                            setShowForm(true);
                        }}
                        setPresential={setPresential}
                        inquiryPrice={inquiryPrice}
                        presential={presential}
                        whatsapp={whatsapp}
                    />
                    {showForm && (
                        <FormPayrollLoanOnboarding
                            onNext={() => setStep(1)}
                            inquiryPrice={inquiryPrice}
                            name={name}
                            setName={setName}
                            cpf={cpf}
                            setCPF={setCPF}
                            phone={phone}
                            setPhone={setPhone}
                            birthDate={birthDate}
                            setbirthDate={setbirthDate}
                            setIlliterate={setIlliterate}
                            setlegalRepresentative={setlegalRepresentative}
                            setRecipient={setRecipient}
                            illiterate={illiterate}
                            legalRepresentative={legalRepresentative}
                            recipient={recipient}
                        />
                    )}
                </>
            );
            break;
        case 1:
            if (legalRepresentative) {
                component = (
                    <>
                        <RepresentantForm
                            nameRepresentant={nameRepresentant}
                            setNameRepresentant={setNameRepresentant}
                            cpfRepresentant={cpfRepresentant}
                            setCPFRepresentant={setCPFRepresentant}
                            phoneRepresentant={phoneRepresentant}
                            setPhoneRepresentant={setPhoneRepresentant}
                            birthDateRepresentant={birthDateRepresentant}
                            setbirthDateRepresentant={setbirthDateRepresentant}
                            back={() => setStep(0)}
                            next={() => setStep(2)}
                        />
                    </>
                );
            } else {
                setStep(2);
            }
            break;
        case 2:
            component = (
                <>
                    <Contracts
                        contracts={contracts}
                        setContracts={setContracts}
                        contractNumber={contractNumber}
                        setContractNumber={setContractNumber}
                        value={Number(value)}
                        setValue={setValue}
                        installmentsValue={Number(installmentsValue)}
                        setInstallmentsValue={setInstallmentsValue}
                        installments={installments}
                        setInstallments={setInstallments}
                        bankName={bankName}
                        setBankName={setBankName}
                        contractDate={contractDate}
                        setContractDate={setContractDate}
                        back={() => legalRepresentative ? setStep(1) : setStep(0)}
                        next={() => setStep(3)}
                    />
                </>
            );
            break;
        case 3:
            if (recipient) {
                component = (
                    <BankData
                        text={
                            legalRepresentative
                                ? "Dados bancários do representante legal"
                                : "Dados bancários do beneficiário"
                        }
                        subtext={
                            legalRepresentative
                                ? "Preencha os campos abaixo com os dados bancários do representante legal."
                                : "Preencha os campos abaixo com os dados do beneficiário."
                        }
                        bankNameData={bankNameData}
                        agency={agency}
                        account={account}
                        setBankNameData={setBankNameData}
                        setAgency={setAgency}
                        setAccount={setAccount}
                        back={() => setStep(2)}
                        next={() => setStep(4)}
                    />
                );
            } else {
                setStep(4);
            }
            break;
        case 4:
            component = (
                <ConfirmData
                    contracts={contracts}
                    setContracts={setContracts}
                    nameRepresentant={nameRepresentant}
                    cpfRepresentant={cpfRepresentant}
                    phoneRepresentant={phoneRepresentant}
                    birthDateRepresentant={birthDateRepresentant}
                    name={name}
                    cpf={cpf}
                    phone={phone}
                    birthDate={birthDate}
                    contractNumber={contractNumber}
                    value={value}
                    installmentsValue={installmentsValue}
                    installments={installments}
                    bankName={bankNameData}
                    agency={agency}
                    account={account}
                    contractDate={contractDate}
                    setName={setName}
                    setCPF={setCPF}
                    setPhone={setPhone}
                    setbirthDate={setbirthDate}
                    setNameRepresentant={setNameRepresentant}
                    setCPFRepresentant={setCPFRepresentant}
                    setPhoneRepresentant={setPhoneRepresentant}
                    setbirthDateRepresentant={setbirthDateRepresentant}
                    setBankName={setBankName}
                    setContractDate={setContractDate}
                    setInstallments={setInstallments}
                    setContractNumber={setContractNumber}
                    setValue={setValue}
                    setInstallmentsValue={setInstallmentsValue}
                    back={() => setStep(3)}
                    next={() => onSendLink()}
                    setAccount={setAccount}
                    setAgency={setAgency}
                    complementText={complementText}
                    replaceCurrentUnityComplementText={replaceCurrentUnityComplementText}
                    setComplementText={setcomplementText}
                    setReplaceCurrentUnityComplementText={setReplaceCurrentUnityComplementText}
                    send={onSendLink}
                />
            );
            break;
        case 5:
            component = (
                <LinkSent
                    onNewOnboarding={onNewOnboarding}
                    onReturnMenu={onReturnMenu}
                    inquiryType={EInquiryType.PayrollLoanVideoOnboarding}
                />
            );
            break;
        default:
            component = null;
    }

    return (
        <Layout>
            <SubHeader title="Consulta" subtitle="Onboarding por vídeo"/>
            <ScreenWithSubHeaderContainer>{component}</ScreenWithSubHeaderContainer>
        </Layout>
    );
};
export default PayrollLoanVideoOnboarding;
