import { format, parseISO } from "date-fns";
import { useMemo, useState } from "react";
import { MailingData } from "../../../shared/models/backend/mailing/mailing";
import { ValidationUtils } from "../../../utils/validation-utils";

type ClientDataFormProps = {
  phone: string;
  email: string;
  cardAcquired?: "yes" | "no" | any;
  cardExpirationDate?: number;
} & Pick<
  MailingData,
  | "bairro"
  | "cep"
  | "cidade"
  | "ufEndereco"
  | "numero"
  | "complemento"
  | "logradouro"
  | "nomeMae"
  | "escolaridade"
  | "nomePai"
  | "dataEmissao"
  | "ufEmissor"
  | "orgaoEmissor"
  | "numeroDocumento"
>;

type ClientDataFormErrorsProps = Partial<
  Record<keyof ClientDataFormProps, string>
>;

const mandatoryFields = [
  "phone",
  "email",
  "logradouro",
  "numero",
  "bairro",
  "cidade",
  "cep",
  "ufEndereco",
  "numeroDocumento",
  "ufEmissor",
  "orgaoEmissor",
  "dataEmissao",
  "cardAcquired",
];

export const useClientDataForm = () => {
  const [form, setForm] = useState<ClientDataFormProps | undefined>();
  const [formErrors, setFormErrors] = useState<ClientDataFormErrorsProps>({});

  const getFormInitialValues = (
    mailingData: MailingData,
    mailingUserData: any
  ): ClientDataFormProps => {
    const {
      bairro,
      cep,
      cidade,
      ufEndereco,
      numero,
      complemento,
      logradouro,
      nomeMae,
      escolaridade,
    } = mailingData;

    return {
      phone: (
        mailingUserData?.phone ||
        mailingData.telefones[0] ||
        ""
      ).replaceAll("+55", ""),
      email: mailingUserData?.email || mailingData.emails[0],
      bairro: mailingUserData?.bairro || bairro || "",
      cep: mailingUserData?.cep || cep || "",
      cidade: mailingUserData?.cidade || cidade || "",
      ufEndereco: mailingUserData?.ufEndereco || ufEndereco || "",
      numero: mailingUserData?.numero || numero || "",
      complemento: mailingUserData?.complemento || complemento || "",
      logradouro: mailingUserData?.logradouro || logradouro || "",
      nomeMae: mailingUserData?.nomeMae || nomeMae || "",
      escolaridade: mailingUserData?.escolaridade || escolaridade || "",
      nomePai: mailingUserData?.escolaridade || "",
      dataEmissao: mailingUserData?.dataEmissao
        ? format(parseISO(mailingUserData?.dataEmissao), "dd/MM/yyyy")
        : "",
      ufEmissor: mailingUserData?.ufEmissor || "",
      orgaoEmissor: mailingUserData?.orgaoEmissor || "",
      numeroDocumento: mailingUserData?.numeroDocumento || "",
      cardAcquired:
        typeof mailingUserData?.cardAcquired === "boolean"
          ? mailingUserData?.cardAcquired
            ? "yes"
            : "no"
          : "",
      cardExpirationDate: mailingUserData?.cardExpirationDate,
    };
  };

  const validateForm = (): boolean => {
    let isFormValid = true;
    mandatoryFields.forEach((field) => {
      const isFieldValid = form[field] !== undefined && form[field] !== "";

      if (!isFieldValid) {
        setFormErrors((current) => ({
          ...current,
          [field]: "Campo obrigatório.",
        }));
        isFormValid = false;
        return;
      }

      if (field === "cardAcquired") {
        if (form.cardAcquired === "yes" && !form.cardExpirationDate) {
          isFormValid = false;
        }
      }

      if (field === "phone") {
        const isPhoneValid = ValidationUtils.validatePhone(form[field]);
        if (!isPhoneValid) {
          setFormErrors((current) => ({
            ...current,
            [field]: "Campo telefone é inválido.",
          }));
          isFormValid = false;
          return;
        }
      } else if (field === "email") {
        const isEmailValid = ValidationUtils.validateEmail(form[field]);
        if (!isEmailValid) {
          setFormErrors((current) => ({
            ...current,
            [field]: "Campo email é inválido.",
          }));
          isFormValid = false;
          return;
        }
      }
    });

    return isFormValid;
  };

  console.log(formErrors);

  const isFormValid = useMemo(() => {
    if (!form) return false;
    return validateForm();
  }, [form]);

  const getChangedData = (baseData: MailingData, userData: any) => {
    const result: any = {};

    for (const key in form) {
      if (form[key] !== (userData?.[key] || baseData?.[key])) {
        result[key] = form[key];
      }
    }

    result.cardAcquired = result.cardAcquired === "yes" ? true : false;

    if (!result.cardAcquired) {
      delete result.cardExpirationDate;
    }

    result.cardExpirationDate = Number(result.cardExpirationDate);

    result.dataEmissao = new Date(result.dataEmissao);

    return result;
  };

  return {
    form,
    setForm,
    formErrors,
    setFormErrors,
    getFormInitialValues,
    validateForm,
    getChangedData,
    isFormValid,
  };
};
