import DescriptionIcon from '@mui/icons-material/Description';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import UnitiesManagementIcon from '@mui/icons-material/DomainOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import UserManagementIcon from '@mui/icons-material/ManageAccountsOutlined';
import {UserProfileType} from "../../../../shared/models/user-profile-type";

export const MATERIAL_ICONS: { [key: string]: React.ElementType } = {
    "document": DescriptionIcon,
    "id-card": CardMembershipIcon,
    "phone": PhoneIcon,
    "envelope": EmailIcon,
    "video-outline": VideocamOutlinedIcon,
    "calendar": CalendarTodayIcon,
    "chart": BarChartIcon,
    "piggybank": AccountBalanceIcon,
    "user-cog": UserManagementIcon,
    "manage-unities": UnitiesManagementIcon,
    "credit-card": CreditCardIcon,
    "address-card": AccountCircleIcon,
    "key": VpnKeyIcon,
    "download": DownloadOutlinedIcon,
};

export type MaterialIconName = keyof typeof MATERIAL_ICONS;

export interface MenuOption {
    iconName: MaterialIconName;
    text: string;
    onClick?: () => void;
    route?: string;
    navigate?: (route: string) => void;
    multiOption?: MenuOption[];
    roles?: UserProfileType[];
    isOpen?: boolean;
    onToggle?: () => void;
}