import { Box, Button, Stack } from "@mui/material";

import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";
import { StyledCircularProgress } from "../../../components/Loading/styles";
import useMailingOnBoardingService from "../../../shared/services/useMailingOnBoardingService";
import ContainerContent from "../components/ContainerContent";
import { EMailingStepsEnum, MailingStepProps } from "../index.types";
import classes from "./signed-contract.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const PdfViewer = ({ pdfUrl }: { pdfUrl: string }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Box className={classes.contractBox}>
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (_, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </Box>
  );
};

const ReadContractStep = ({ setActiveStep }: MailingStepProps) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [pdf, setPdf] = useState<string | null>(null);
  const { getMailingSignedContract, finishMailingFlow } =
    useMailingOnBoardingService();

  const handleFinishMailingFlow = () => {
    setIsLoading(true);
    finishMailingFlow(id)
      .then(() => {
        setActiveStep(EMailingStepsEnum.SentConfirmation);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    getMailingSignedContract(id)
      .then((response) => {
        setPdf(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  return (
    <Stack>
      <ContainerContent id="mailing-contract">
        {pdf ? (
          <PdfViewer pdfUrl={pdf} />
        ) : (
          <Stack
            width="100%"
            height="300px"
            alignItems="center"
            justifyContent="center"
          >
            <StyledCircularProgress size={40} />
          </Stack>
        )}
      </ContainerContent>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        gap="24px"
        mt="8px"
      >
        <Button
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          sx={{ paddingInline: "24px" }}
          startIcon={
            isLoading ? <StyledCircularProgress size={16} /> : undefined
          }
          onClick={handleFinishMailingFlow}
        >
          Concluir
        </Button>
      </Stack>
    </Stack>
  );
};

export default ReadContractStep;
