import React, {useEffect, useState} from "react";
import Item from "./Item";
import styled from "styled-components";
import Routes from "../../../../constants/Routes";
import {useNavigate} from "react-router-dom";
import {MenuProps} from "../../Menu";
import {Palette} from "../../../../shared/theme/palette";
import useMenu from "../../hooks/useMenu";
import {logoutMenuOption} from "../../hooks/menu-options";
import { useThemeWhiteLabel } from "../../../../hooks/useThemeWhiteLabel";

const Container = styled.div<{ primaryColor: string }>`
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 64px;
    height: 100%;
    box-sizing: border-box;
    background: ${Palette.white};
    border-right: 1px solid ${(props) => props.primaryColor};
    z-index: 9999;
`;
const NewItemContainer = styled.div`
    position: absolute;
    bottom: 90px;
`;

const ItemList = ({onExpandChange}: MenuProps) => {
    const [activeItem, setActiveItem] = useState<string | null>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (onExpandChange) onExpandChange(isExpanded);
    }, [isExpanded, onExpandChange]);
    const {menuGroups} = useMenu()
    const theme = useThemeWhiteLabel();

    return (
        <Container primaryColor={theme.primaryColor}>
            {menuGroups.map((item, index) => {
                return (
                    <Item
                        key={index}
                        activeItem={activeItem}
                        setActiveItem={setActiveItem}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        {...item}
                    />
                );
            })}
            <NewItemContainer>
                <Item
                    onClick={() => {
                        localStorage.clear();
                        navigate(Routes.Root);
                    }}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    {...logoutMenuOption}
                />
            </NewItemContainer>
        </Container>
    );
};

export default ItemList;
