import EInquiryType from "../models/backend/inquiry/EInquiryType";

export class InquiryUtils {
    static getEstimatedTime(inquiryType) {
        switch (inquiryType) {
            case EInquiryType.Telefone:
            case EInquiryType.Email:
                return '10 minutos.';
            case EInquiryType.VideoOnboarding:
                return '1 hora, após o envio da documentação.';
            case EInquiryType.PayrollLoanVideoOnboarding:
                return '1 hora, após o envio da documentação.';
            default:
                return '1 hora.';
        }
    }
}