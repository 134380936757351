import {
    FormContainer,
    HorizontalButtonsContainer,
    InstructionsText,
    ScreenWithSubHeaderContainer
} from "../../../../theme/GlobalStyles";
import InquiryPriceAndBalanceCards from "../InquiryPriceAndBalanceCards";
import Input from "../../../../components/Input/Input";
import {Button} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {AccountContext} from "../../../../shared/context/AccountContext";
import {useSubscriptionService} from "../../../../shared/services/useSubscriptionService";
import {usePlanService} from "../../../../shared/services/usePlanService";
import EInquiryType from "../../../../shared/models/backend/inquiry/EInquiryType";
import {toast} from "react-toastify";
import {ValidationUtils} from "../../../../utils/validation-utils";

interface PhoneInquiryFormProps {
    onNext: () => void;
    phone: string;
    setPhone: (cpf: string) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

const PhoneInquiryForm = ({...props}: PhoneInquiryFormProps) => {
    const [inquiryPrice, setInquiryPrice] = useState<number>();
    const {ids} = useContext(AccountContext)
    const {buscarAssinaturaAtiva} = useSubscriptionService();
    const {getProductPrices} = usePlanService();
    useEffect(() => {
        props.setLoading(true)
        buscarAssinaturaAtiva(ids.unitId).then((subscription) => {
            getProductPrices(subscription.planoId).then((prices) => {
                const price = prices?.find((price) => price.type == EInquiryType.Telefone)?.price;
                setInquiryPrice(price)
                if (!price) {
                    toast.error('Seu plano atual não permite esse tipo de consulta.', {
                        autoClose: false,
                        closeButton: true,
                        toastId: 'no-price-error'
                    })
                }
            })
        })
            .finally(() => props.setLoading(false))
    }, [ids]);
    return (
        <>
            <ScreenWithSubHeaderContainer>
                <>
                    <InquiryPriceAndBalanceCards inquiryPrice={inquiryPrice}/>
                    <InstructionsText>{'Para fazer uma consulta por telefone, basta preencher o número desejado no campo abaixo.'}</InstructionsText>
                    <FormContainer>
                        <Input placeholder='Número *'
                               mask='(99) 99999-9999'
                               onChange={props.setPhone}
                               value={props.phone}
                               required
                               validationCallback={ValidationUtils.validatePhone}
                               error={'Telefone inválido'}
                               label={'Número'}/>
                        <HorizontalButtonsContainer>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                disabled={!inquiryPrice || props.loading || !props.phone}
                                onClick={props.onNext}
                            >
                                {'Solicitar Consulta'}
                            </Button>
                        </HorizontalButtonsContainer>
                    </FormContainer>
                </>
            </ScreenWithSubHeaderContainer>
        </>
    )
}
export default PhoneInquiryForm;